import './style.css'
import * as THREE from 'three'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
const createTextGeometry = require('three-bmfont-text');
import torusTextVertex from './shaders/torusText_vertex.glsl'
import torusTextFragment from './shaders/torusText_fragment.glsl'
import vertex from './shaders/newVertex.glsl'
import fragment from './shaders/newFragment.glsl'
import {Text} from 'troika-three-text'
var MSDFShader = require('./msdf')
var renderTarget
var renderTargetScene
var renderTargetCamera

require('./load')({
    font: 'fnt/Manifold_r.json',
    image: 'fnt/Manifold_r.png'
  }, start)

function start(font, fontTexture) {
    const loader = new THREE.TextureLoader()
    const star = loader.load('./star.png')
    const trippyColor = loader.load('./trippyColor.png')
    const particleTest = loader.load('./star3.webp')
    let textIndex = 0;
    let textTime = 0;

    console.clear();

    gsap.registerPlugin(ScrollTrigger);

    // Raycaster
    const raycaster = new THREE.Raycaster()
    const pointer = new THREE.Vector2()
    const point = new THREE.Vector3()
    

    // --- CONSTS

    const COLORS = {
        background: 'black',
        light: '#ffffff',
        sky: '#aaaaff',
        ground: '#88ff88',
        blue: 'steelblue'
    }

    const PI = Math.PI;

    // --- SCENE

    const scenes = {
        real: new THREE.Scene(),
        wire: new THREE.Scene()
    }

    //scenes.wire.overrideMaterial = wireframeMaterial;

    let size = { width: 0, height: 0 }

    const scene = new THREE.Scene();
    scenes.real.background = new THREE.Color(COLORS.background);
    scenes.real.fog = new THREE.Fog(COLORS.background, 15, 20);
    scenes.wire.background = new THREE.Color(COLORS.blue);

    const views = [
        { height: 1, bottom: 0, scene: scenes.real, camera: null},
        { height: 0, bottom: 0, scene: scenes.wire, camera: null}
        ];


    // --- RENDERER

    const renderer = new THREE.WebGLRenderer({
        antialias: true
    })

    const container = document.querySelector('.canvas-container');
    container.appendChild( renderer.domElement );

    // --- CAMERA
    let cameraTarget = new THREE.Vector3(0, 0, 0);
    views.forEach(view => {
        view.camera = new THREE.PerspectiveCamera(
            75, 
            size.width / size.height,
            0.1, 
            100);

            view.camera.position.set(0, 0, 2.5);
            view.scene.add(view.camera);
        })  

    // --- LIGHTS
    const pointLight = new THREE.PointLight(0xffffff, 0.1);
    pointLight.position.x = 2;
    pointLight.position.y = 3;
    pointLight.position.z = 4;
    scenes.real.add(pointLight);

    let particlesMesh = addGalaxyParticles()

    // ADD HEADLINE TEXT
    let customText = addHeadlines();
    let constructionText = addHeadlines2();
    console.log("constructionText: ", constructionText);
    // calculate Size of HEADLINE
    customText.geometry.computeBoundingBox()
    const low = customText.geometry.boundingBox.min;
    const high = customText.geometry.boundingBox.max;

    // ADD SMALL TEXT TO SCENE
    let myText = addText();
        
    // ADD TORUS WITH TEXT TO SCENE
    let torus = addTorusText(font, fontTexture);
    let torusMesh = torus[0];
    let torusText = torus[1];
    let wireTorus = addWireTorus();
        
    /* 
        ADD HOVERPARTICLES
    */
    let hoverParticlesMaterials = [];

    let options = [
        {
            min_radius: 0.5,
            max_radius: 0.5,
            color: '#f7b373',
            size: 0.9,
            high: high,
            low: low
        },
        {
            min_radius: 0.7,
            max_radius: 0.2,
            color: '#88b3ce',
            size: 0.08,
            high: high,
            low: low
        }
    ];
    
    options.forEach(opt => {
        let particleLayer = addParticles(opt);
        hoverParticlesMaterials.push(particleLayer);
    });

    let cameras = null;
    let tori = null;


    const setupAnimation = () => {
        cameras = { positions: [views[0].camera.position, views[1].camera.position,]}
        console.log(torusMesh.position);
        tori = { position: [torusMesh.position, wireTorus.position],
                rotation: [torusMesh.rotation, wireTorus.rotation]}
        constructionText.position.z = -1;
        constructionText.position.x = -100;
        constructionText.position.y = 2.5;
        ScrollTrigger.matchMedia({"(prefers-reduced-motion: no-preference)": desktopAnimation})
    }

    const desktopAnimation = () => {
        let section = 0;
        const tl = gsap.timeline({
            default:{
                duration: 1,
                ease: "power2.inOut"
            },
            scrollTrigger: {
                trigger: ".page",
                start: "top top",
                end: "bottom top",
                scrub: .1,
                markers:false,
            }
            
        });
        
        tl.to(tori.position, { x: 1.5 }, section);
        tl.to(tori.position, { y: -0.25 }, section);
        tl.to(tori.position, { z: -1.0 }, section);
        tl.to(tori.rotation, { y: PI * 1.0 }, section);
        tl.to(myText.position , { z: -20.0 }, section);
        tl.to(customText.position , { z: -20.0 }, section);
        tl.to(constructionText.position , { z: -2.0 }, section);
        tl.to(constructionText.position, {x: -3, ease: "linear"}, section);
        tl.to(constructionText.position, {y : 2.5}, section);
        tl.to(hoverParticlesMaterials[0].position, { z: -20.0 }, section);
        tl.to(hoverParticlesMaterials[1].position, { z: -20.0 }, section);
        tl.to(views[1], {height:1,ease: 'none'}, section)
        
    
        section += 1;
        tl.to(tori.position, { x: 200 }, section);
        tl.to(tori.rotation, { y: PI * 0.5 }, section);
        tl.to(constructionText.position, { z: -20.0 }, section);
        
    };

    setupAnimation();
    
    // --- ON RESIZE

    const onResize = () => {
        console.log('resize');
        size.width = container.clientWidth;
        size.height = container.clientHeight;

        customText.material.uniforms.viewport.value = new THREE.Vector2(size.width, size.height);
        customText.material.uniforms.pixelRatio.value = Math.abs(Math.min(window.devicePixelRatio, 2));

        hoverParticlesMaterials.forEach((m) => {
            m.material.uniforms.viewport.value.x = size.width;
            m.material.uniforms.viewport.value.y = size.height;
            m.material.uniforms.pixelRatio.value = Math.abs(Math.min(window.devicePixelRatio, 2));
        });

        views.forEach(view => {
            view.camera.aspect = size.width / size.height
            view.camera.updateProjectionMatrix()
        })
        
        renderer.setSize(size.width, size.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))	
    }

    window.addEventListener('resize', onResize)
    onResize();

    /**
     * Animation Functions
    */
    document.addEventListener('mousemove', animateParticles);
    document.addEventListener('mousemove', hoverText);
    document.addEventListener("touchmove", onTouchMove);
    document.addEventListener("touchend", onTouchEnd);
    raycasterEvent();

    let mouseX1 = 0;
    let mouseY1 = 0;
    let mouseX2 = 0;
    let mouseY2 = 0;
    
    function animateParticles(event) {
        mouseY1 = event.clientY;
        mouseX1 = event.clientX;
        console.log('MouseX: ' + mouseX1)
        console.log('MouseY: ' + mouseY1)
    };
    
    function hoverText(event){
        mouseY2 = event.clientY/window.innerHeight;
        mouseX2 = event.clientX/window.innerWidth;
        customText.material.uniforms.uMouse.value = new THREE.Vector2(mouseX2, mouseY2)
        hoverParticlesMaterials.forEach((m) => {
            m.material.uniforms.mouse.value = new THREE.Vector2(mouseX2, mouseY2)
        });
    };

    function raycasterEvent(){
        let mesh1 = new THREE.Mesh(
            new THREE.PlaneBufferGeometry(10,10,10,10),
            new THREE.MeshBasicMaterial({color: 0xff0000, wireframe: true})
        )
        // debug tool to see the mouse in the scene
        let testMesh = new THREE.Mesh(
            new THREE.SphereBufferGeometry(0.1,10,10),
            new THREE.MeshBasicMaterial({color: 0xff0000, wireframe: true})
        )
        let mesh2 = new THREE.Mesh(
            new THREE.PlaneBufferGeometry(1,1,1),
            new THREE.MeshBasicMaterial({color: 0xff0000, wireframe: true})
        )

        //scene.add(mesh1, mesh2)
        
        document.addEventListener("pointermove", (event) => {
            pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
            pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

            raycaster.setFromCamera( pointer, views[0].camera );
            const intersects = raycaster.intersectObjects( [mesh1]);
            if(intersects[0]){
                mesh2.position.copy(intersects[0].point)
                point.copy(intersects[0].point)
            }
        });
    };

    function onTouchMove(e){
        if(e.touches.length > 0 ){
        mouseX1 = e.touches[0].clientX;
        mouseY1 = e.touches[0].clientY;
        mouseX2 = e.touches[0].clientX;
        mouseY2 = e.touches[0].clientY;
        }
    };
    
    function onTouchEnd(e){
    mouseX1 = -9999;
    mouseY1 = -9999;
    };


    /**
        // Animation LOOP
    */
    const clock = new THREE.Clock()

    // --- TICK
    const tick = () => {
        const elapsedTime = clock.getElapsedTime()
        
        // Update objects
        particlesMesh.rotation.x = mouseY1 * 0.00006;
        particlesMesh.rotation.y = (mouseX1 * 0.00006) + (- elapsedTime * 0.01);
        customText.material.uniforms.time.value = elapsedTime * 2;
        torusMesh.material.uniforms.uTime.value = elapsedTime;
        torusMesh.rotation.y = 0.25 * elapsedTime;
        torusMesh.rotation.z = .05 * elapsedTime;
        wireTorus.rotation.y = 0.25 * elapsedTime;
        wireTorus.rotation.z = .05 * elapsedTime;
        hoverParticlesMaterials.forEach((m) => {
            m.material.uniforms.time.value = elapsedTime * 2;
            m.material.uniforms.uMouse.value = point;
        });

        let longTexts = [
            'TELLING WEB3 STORIES',
            'DESIGNING WEB3 WORLDS',
            'DEVELOPING WEB3 EXPERIENCES',
            'BUILDING WEB3 COMMUNITIES',
            'PLANNING WEB3 STRATEGIES'
        ]
        let shortTexts = [
            'TELLING',
            'DESIGNING',
            'DEVELOPING',
            'BUILDING',
            'PLANNING'
        ]

        // Add changing text to torus
        if(Math.floor(elapsedTime) % 6 == 0) {
            let oldTime = textTime;
            textTime = Math.floor(elapsedTime);
            if(oldTime != textTime) {
                renderTargetScene.remove(torusText);
                torusText.geometry.dispose();
                torusText.geometry = createTorusFontGeometry(shortTexts[textIndex]);
                renderTargetScene.add(torusText);
                torusMesh.material.uniforms.uTexture.value = renderTarget.texture;
                myText.text = longTexts[textIndex];
                
                textIndex++;
                if (textIndex == 5) {
                    textIndex = 0;
                }
            }
        }
        
        // Render
        renderer.setRenderTarget(renderTarget);
        renderer.render(renderTargetScene, renderTargetCamera);
        renderer.setRenderTarget(null);

        views.forEach(view => {
            view.camera.lookAt(cameraTarget);
            let bottom = size.height * view.bottom
            let height = size.height * view.height
            renderer.setViewport(0,0, size.width, size.height)
            renderer.setScissor(0, bottom, size.width, height)
            renderer.setScissorTest(true)
            renderer.render(view.scene, view.camera);
        })
        
        window.requestAnimationFrame(() => tick())
    }

    tick();
    
    function getRandomIntInclusive(min, max) {
        return (((Math.random()-0.5) * (max - min) + min));
    };

    /**
            // FUNCTIONS TO CREATE ELEMENTS
    */

    /*
        // function to add galaxy particles
    */
    function addGalaxyParticles() {
        const geometry = new THREE.TorusGeometry( 1.5, .2, 16, 100 );
        const particlesGeometry = new THREE.BufferGeometry;
        const particlesCnt = 2000;

        // save the positions of particles in an array
        const posArray = new Float32Array(particlesCnt * 3);
        for (let i = 0; i< particlesCnt * 3; i++) {
            posArray[i] = (Math.random() - 0.5) * 5;
        }
        particlesGeometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3));

        // Materials
        const points = new THREE.PointsMaterial({
            size: 0.005,
            sizeAttenuation: true,
            color: 'red'
        });

        const particles = new THREE.PointsMaterial({
            size: 0.02,
            map: star,
            transparent: true,
            sizeAttenuation: true,
            color: 'white',
            blending: THREE.AdditiveBlending
        });

        const material = new THREE.MeshBasicMaterial();
        material.color = new THREE.Color(0xff0000);

        // Mesh
        const particlesMesh = new THREE.Points(particlesGeometry, particles);
        scenes.real.add(particlesMesh);
        return particlesMesh;
    };

    /*
        // function to add headline text
    */
    function addHeadlines(){
        var geom = createTextGeometry({
            text: 'M3SHPIT',
            font: font,
            fontSize: 1,
            align: 'center',
            flipY: fontTexture.flipY,
        })
        
        var materialText = new THREE.RawShaderMaterial(MSDFShader({
            map: fontTexture,
            transparent: true,
            color: 0xfffff0,
            side: THREE.DoubleSide
        }))
        materialText.uniforms.viewport.value = new THREE.Vector2(window.innerWidth, window.innerHeight);
        materialText.uniforms.pixelRatio.value = window.devicePixelRatio;

        let layout = geom.layout
        let customText = new THREE.Mesh(geom, materialText)
        customText.scale.set(0.0117, -0.0117,0.0117);
        customText.position.set(-1.24, -0.1, -0.0012)
        
        scenes.real.add(customText);
        return customText;
    };

    /*
        // function to add construction headline text
    */
    function addHeadlines2(){
        var geom = createTextGeometry({
            text: 'UNDER CONSTRUCTION',
            font: font,
            fontSize: 1,
            align: 'center',
            flipY: fontTexture.flipY,
        })
        
        var materialText = new THREE.RawShaderMaterial(MSDFShader({
            map: fontTexture,
            transparent: true,
            color: 0xfffff0,
            side: THREE.DoubleSide
        }))
        materialText.uniforms.viewport.value = new THREE.Vector2(window.innerWidth, window.innerHeight);
        materialText.uniforms.pixelRatio.value = window.devicePixelRatio;

        let layout = geom.layout
        let constructionText = new THREE.Mesh(geom, materialText)
        constructionText.scale.set(0.0117, -0.0117,0.0117);
        constructionText.position.set(-2, -0.1, -0.0012)
        
        scenes.wire.add(constructionText);
        return constructionText;
    };

    /*
        // function to add particles
    */ 
    function addParticles(opt) {
        let count = 250
        //let min_radius = 0.5;
        //let max_radius = 1;
        let particleGeo = new THREE.PlaneBufferGeometry(1,1);
        let geo = new THREE.InstancedBufferGeometry();
        geo.instanceCount = count;
        geo.setAttribute('position', particleGeo.getAttribute('position'));
        geo.index = particleGeo.index;

        let pos = new Float32Array(count * 3);

        function lerp(a,b,t) {
            return a * (1 - t) + b * t;
        }

        for (let i = 0; i < count; i++) {
            // let theta = Math.random() * Math.PI * 2;
            // let r = lerp(opt.min_radius, opt.max_radius, Math.random());
            // let x = r*Math.sin(theta);
            // let y = (Math.random()-0.5)*0.5;
            // let z = r*Math.cos(theta);
            // let x = (Math.random()-0.5)*1.5;
            // let y = (Math.random()-0.5)*1.5;
            // let z = (Math.random()-0.5)*1.5;
            let x = getRandomIntInclusive(0., 2.5);
            let y = getRandomIntInclusive(0, opt.high.y/opt.low.y-0.1) +0.05;
            let z = getRandomIntInclusive(-0.1, 0.1);

            pos.set([
                x,y,z
            ],i*3);
        }

        geo.setAttribute('pos', new THREE.InstancedBufferAttribute(pos, 3, false));

        let hoverParticlesMaterial = new THREE.ShaderMaterial({
            extensions: {
                derivatives: '#extension GL_OES_standard_derivatives : enable',
            },
            side: THREE.DoubleSide,
            uniforms: {
                uTexture: { value: particleTest },
                time: { value: 0 },
                uMouse: { value: new THREE.Vector2(0,0) },
                mouse: { value: new THREE.Vector2(0,0) },
                uColor: { value: new THREE.Color(opt.color) },
                size: { value: opt.size },
                resolution: { value: new THREE.Vector4() },
                viewport: { value: new THREE.Vector2(window.innerWidth, window.innerHeight) },
                pixelRatio: { value: window.devicePixelRatio }

            },
            transparent: true,
            depthTest: true,
            vertexShader: vertex,
            fragmentShader: fragment
        });

        let hoverParticles2 = new THREE.Mesh(geo, hoverParticlesMaterial);
        scenes.real.add(hoverParticles2);
        return hoverParticles2;
    };

    /*
        // function to add small text
    */ 
    function addText() {
        // Create:
        const myText = new Text()
        scenes.real.add(myText)

        // Set properties to configure:
        myText.text = 'PLANNING WEB3 EXPERIENCES'
        myText.fontSize = 0.1
        myText.letterSpacing = 0.25
        myText.anchorX = 'center'
        myText.anchorY = 'middle'
        myText.rotation.x = 0
        myText.position.z = -0.1
        myText.position.y = -0.3
        myText.color = '#ffffff'
        myText.outlineWidth = 0.0
        myText.outlineColor = '#ff0000'
        myText.outlineOpacity = 0
        myText.fillOpacity = 1
        myText.strokeColor = '#ff0000'
        myText.strokeWidth = 0.0
        myText.strokeOpacity = 0

        // Update the rendering:
        myText.sync()

        return myText
    };
    
    /*
    // function to add text on torus
    */ 
    function addTorusText(font, texture) {
        // Create geometry of packed glyphs
        let torusFontGeometry = createTorusFontGeometry('PLANNING')
        
        let torusFontMaterial = new THREE.RawShaderMaterial(
            MSDFShader({
            map: texture,
            side: THREE.DoubleSide,
            transparent: true,
            negate: false,
            color: 0xffffff
            })
        );
        
        // Render Target setup
        renderTarget = new THREE.WebGLRenderTarget(
            window.innerWidth,
            window.innerHeight
        );
        
        renderTargetCamera = new THREE.PerspectiveCamera(45, 1, 0.1, 1000);
        renderTargetCamera.position.z = 2.5;
        
        renderTargetScene = new THREE.Scene();
        renderTargetScene.background = new THREE.Color("#000000");
        
        // Create text with font geometry and material
        let torusText = new THREE.Mesh(torusFontGeometry, torusFontMaterial);
        
        // Adjust text dimensions
        torusText.position.set(-0.965, -0.275, 0);
        torusText.rotation.set(Math.PI, 0, 0);
        torusText.scale.set(0.008, 0.02, 1);
        
        // Add text to RT scene
        renderTargetScene.add(torusText);

        //scene.add(torusText); // Add to main scene
        
        //let geometry = new THREE.TorusKnotGeometry(1.5, .2, 768, 3, 4, 3);
        let geometry = new THREE.TorusGeometry(1.5, .2, 64, 100 );
    
        let material = new THREE.ShaderMaterial({
            vertexShader: torusTextVertex,
            fragmentShader: torusTextFragment,
            uniforms: {
                uTime: { value: 0 },
                uTexture: { value: renderTarget.texture },
                vTexture: { value: trippyColor }
            },
            side: THREE.DoubleSide,
            color: 0x000000
        });
    
        let torusMesh = new THREE.Mesh(geometry, material);
    
        scenes.real.add(torusMesh);
        return [torusMesh, torusText];
        
    };

    function createTorusFontGeometry(displayText) {
        let torusFontGeometry = createTextGeometry({
            font,
            text: displayText,
        });
        return torusFontGeometry;
    };

    function addWireTorus(){
        const geometry = new THREE.TorusGeometry( 1.5, .2, 32, 50 );
        const material = new THREE.MeshBasicMaterial( { color: 0xffffff, wireframe: true } );
        const torus = new THREE.Mesh( geometry, material );
        scenes.wire.add( torus );
        return torus;
    }
};