
var loadFont = require('load-bmfont')
const threeBmfontText = require('three-bmfont-text')
global.THREE = require('three')

const loader = new THREE.TextureLoader()

// A utility to load a font, then a texture
module.exports = function (opt, cb) {
  loadFont(opt.font, function (err, font) {
    if (err) throw err
    loader.load(opt.image,  function (texture){
      cb(font,texture) },
      // Function called when download progresses
      function ( xhr ) {
        console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
      },
      // Function called when download errors
      function ( xhr ) {
          console.log( 'An error happened' );
      })
    
    /*
    THREE.ImageUtils.loadTexture(opt.image, undefined, function (tex) {
      cb(font, tex)
    })
    */
  })
}